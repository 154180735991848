<template>
  <div class="privada mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>
            Agenda Privada
          </v-card-title>
          <v-card-subtitle>
            Selecciona un cliente para tomar una cita en agenda privada
          </v-card-subtitle>
          <v-card-text style="padding: 0px 16px !important">
            <v-row align="center">
              <v-col cols="12" sm="6" md="8">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    style="padding: 0px 6px !important"
                  >
                    <!-- Seleccion de cliente -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-autocomplete
                          v-model="id"
                          v-on="on"
                          v-bind="attrs"
                          dense
                          outlined
                          rounded
                          :loading="isLoading"
                          item-value="id"
                          :items="lista"
                          no-filter
                          label="Selecciona un cliente"
                          ref="clientes"
                          hide-no-data
                          @update:search-input="customFilter"
                          @change="detailCliente()"
                        ></v-autocomplete>
                      </template>
                      <span>Filtra tus clientes por nombre o cedula / ID</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    style="padding: 0px 6px !important"
                  >
                    <!-- Fecha -->
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          outlined
                          rounded
                          dense
                          label="Fecha"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        scrollable
                        v-model="date"
                        :min="min"
                        locale="es-co"
                        color="primary"
                        first-day-of-week="1"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Listado de servicios -->
                  <v-col
                    cols="12"
                    md="9"
                    sm="12"
                    style="padding: 0px 6px !important"
                    v-if="cliente"
                  >
                    <v-autocomplete
                      dense
                      outlined
                      rounded
                      v-model="servicio"
                      :loading="isLoadingS"
                      append-icon="mdi-magnify"
                      item-text="servicio"
                      :items="servicios"
                      :filter="customFilterServicios"
                      label="Selecciona un servicio"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <!-- Duracion del servicio -->
                  <v-col
                    cols="12"
                    md="3"
                    sm="6"
                    style="padding: 0px 6px !important"
                    v-if="servicio"
                  >
                    <v-select
                      width="200"
                      outlined
                      rounded
                      dense
                      v-model="servicio.duracion"
                      color="primary"
                      :items="proceso"
                      item-color="primary"
                      item-text="hora"
                      item-value="minutos"
                      :menu-props="{ maxHeight: '400' }"
                      label="Duración"
                      persistent-hint
                    ></v-select>
                  </v-col>
                  <!-- Listado de profesionales por servicio -->
                  <v-col
                    cols="12"
                    md="8"
                    sm="6"
                    style="padding: 0px 6px !important"
                    v-if="servicio"
                  >
                    <v-autocomplete
                      dense
                      outlined
                      rounded
                      v-model="profesional"
                      :loading="isLoadingP"
                      append-icon="mdi-magnify"
                      item-text="nombre"
                      :items="profesionales"
                      :filter="customFilterProfesionales"
                      label="Selecciona un profesional"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="6"
                    style="padding: 0px 6px !important"
                    v-if="profesional"
                  >
                    <v-select
                      :items="horasDisponibles"
                      v-model="horacita"
                      rounded
                      dense
                      outlined
                      label="Selecciona una hora"
                      return-object
                      @change="dialog = true"
                    ></v-select>
                    <v-dialog v-model="dialog" persistent max-width="350">
                      <v-card>
                        <v-card-title class="headline">
                          Notas de la cita
                        </v-card-title>
                        <v-card-text>
                          <v-textarea
                            v-model="notas"
                            auto-grow
                            rows="5"
                          ></v-textarea>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="grey"
                            text
                            @click="
                              dialog = false;
                              notas = '';
                            "
                          >
                            cerrar
                          </v-btn>
                          <v-btn
                            :dark="!loading"
                            :color="!loading ? 'black' : 'gray'"
                            :loading="loading"
                            :disabled="loading"
                            @click="horaCita()"
                          >
                            Agendar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4 " sm="6">
                <!-- Detalle cliente seleccionado -->
                <v-card elevation="0" outlined v-if="cliente != null">
                  <v-card-text>
                    <v-row align="center" no-gutters>
                      <v-col>
                        <strong>
                          {{ cliente.nombre }}
                        </strong>
                        <br />
                        <strong> Cc: {{ cliente.cedula }} </strong>
                        <br />
                        <strong> Tel: {{ cliente.telefono }} </strong>
                        <br />
                        <strong>
                          {{ cliente.email }}
                        </strong>
                      </v-col>
                      <v-col align="right">
                        <v-avatar>
                          <img
                            :src="
                              $store.state.centro.route +
                                'clientes/' +
                                cliente.foto
                            "
                          />
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
export default {
  name: "privada",
  data: () => ({
    search: "",
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    min: moment().format("YYYY-MM-DD"),
    id: null,
    loading: false,
    isLoading: false,
    cliente: null,
    clientes: [],
    isLoadingS: false,
    isLoadingP: false,
    servicios: [],
    servicio: null,
    profesionales: [],
    profesional: null,
    horas: [],
    horacita: {},
    dialog: false,
    notas: "",
  }),
  methods: {
    loadClientesLite() {
      this.clientes = [];
      this.isLoading = true;
      const body = {
        route: `/clientes_lite`,
        params: {
          search: this.search,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    detailCliente() {
      const body = {
        route: "/detalle_cliente",
        params: {
          id: this.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.cliente = response.data.data;
          }
        })
        .finally(() => {
          this.id = null;
          this.$refs.clientes.reset();
          if (this.servicios.length == 0) this.loadServicios();
        });
    },
    loadServicios() {
      this.isLoadingS = true;
      const body = {
        route: "/servicios_activos",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.servicios = response.data.data;
            this.servicios = this.servicios.map((servicio) => {
              servicio.duracion = parseInt(servicio.duracion);
              return servicio;
            });
          }
        })
        .finally(() => (this.isLoadingS = false));
    },
    loadProfesionales() {
      const body = {
        route: "/profesional_servicios",
        params: {
          id: this.servicio.id_activo,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data;
        }
      });
    },
    loadHoras() {
      const body = {
        route: "/tiempo_agendado",
        params: {
          date: this.date,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.horas = response.data.data;
        }
      });
    },
    loadHorarios() {
      const body = {
        route: "/sede",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.horarios = JSON.parse(response.data.data.horarios);
        }
      });
    },
    removeAccents(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    customFilter(item) {
      this.search = item;
      if (this.search != null || this.search != undefined) {
        if (this.search != "") this.loadClientesLite();
      }
    },
    customFilterServicios(item, queryText, itemText) {
      const text = this.removeAccents(queryText);
      const textOne = item.servicio.toLowerCase();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilterProfesionales(item, queryText, itemText) {
      const text = this.removeAccents(queryText);
      const textOne = item.nombre.toLowerCase();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    calculateRange(start, end, minutes) {
      let range = [];
      for (
        let hour = moment(start);
        hour.isBefore(end);
        hour.add(minutes, "minutes")
      ) {
        range.push(moment(hour));
      }
      range.push(moment(end));
      return range;
    },
    // Calcular tiemp de duracion del servicio por agendar
    iteracion(inicio, fin) {
      let start = moment(inicio, "HH:mm");
      let end = moment(fin, "HH:mm").add(
        -this.$store.state.centro.agenda,
        "minutes"
      );
      let rangeArray = this.calculateRange(
        start,
        end,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return hora.format("HH:mm");
      });
      return rangeArray;
    },
    horaCita() {
      this.loading = true;
      const cita = {
        cliente: this.cliente.id,
        inicio: moment(this.horacita.value.hora, "h:mm A").format("HH:mm"),
        fin: moment(this.horacita.value.hora, "h:mm A")
          .add(this.servicio.duracion, "minutes")
          .format("HH:mm"),
        date: this.date,
        profesional: this.profesional.id,
        servicio: this.servicio.id_activo,
        nota: this.notas == "" ? null : this.notas,
        horas: this.iteracion(
          moment(this.horacita.value.hora, "h:mm A").format("HH:mm"),
          moment(this.horacita.value.hora, "h:mm A")
            .add(this.servicio.duracion, "minutes")
            .format("HH:mm")
        ),
      };
      let body = {
        route: "/tomar_cita",
        data: {
          data: cita,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.$router.push({
              name: "agenda",
              params: {
                date: this.date,
              },
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    // this.loadClientesLite();
    // this.loadServicios();
    this.loadHoras();
    this.loadHorarios();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      return this.clientes.map((cliente) => {
        return {
          text: `${cliente.nombre} [${cliente.cedula}]`,
          nombre: cliente.nombre,
          cedula: cliente.cedula,
          id: cliente.id,
        };
      });
    },
    proceso() {
      let start = moment("00:00", "HH:mm").add(
        parseInt(this.$store.state.centro.agenda),
        "minutes"
      );
      let end = moment("08:00", "HH:mm");
      let rangeArray = this.calculateRange(
        start,
        end,
        this.$store.state.centro.agenda
      ).map((hora) => {
        return {
          hora:
            parseInt(hora.format("HH")) * 60 + parseInt(hora.format("mm")) < 60
              ? hora.format("mm") + " Min"
              : hora.format("H:mm") + " Hr",
          minutos:
            parseInt(hora.format("HH")) * 60 + parseInt(hora.format("mm")),
        };
      });
      return rangeArray;
    },
    apertura() {
      let horarios = { inicio: null, fin: null };
      if (moment(this.date).isoWeekday() == 7) {
        horarios.inicio = this.horarios.inicio_d;
        horarios.fin = this.horarios.fin_d;
      }

      if (moment(this.date).isoWeekday() == 6) {
        horarios.inicio = this.horarios.inicio_s;
        horarios.fin = this.horarios.fin_s;
      }

      if (moment(this.date).isoWeekday() <= 5) {
        horarios.inicio = this.horarios.inicio_lv;
        horarios.fin = this.horarios.fin_lv;
      }
      return horarios;
    },
    mañana() {
      let start = moment("05:00", "HH:mm");
      let end = moment(this.apertura.inicio, "HH:mm").add(
        -parseInt(this.$store.state.centro.agenda),
        "minutes"
      );
      let rangeArray = this.calculateRange(
        start,
        end,
        this.$store.state.centro.agenda
      ).map((hora) => {
        return {
          hora: hora.format("h:mm A"),
          estado: 0,
        };
      });
      return rangeArray;
    },
    tarde() {
      let start = moment(this.apertura.fin, "HH:mm").add(
        parseInt(this.$store.state.centro.agenda),
        "minutes"
      );
      let end = moment("23:30", "HH:mm");
      let rangeArray = this.calculateRange(
        start,
        end,
        this.$store.state.centro.agenda
      ).map((hora) => {
        return {
          hora: hora.format("h:mm A"),
          estado: 0,
        };
      });
      return rangeArray;
    },
    listaHoras() {
      let horas = [
        {
          header: "Agenda mañana",
        },
        {
          divider: true,
        },
      ];
      const actual = moment(moment().format("h:mm A"), "h:mm A");
      this.mañana.forEach((hora) => {
        let item = {
          text: hora.hora,
          value: {
            hora: hora.hora,
            profesional_id: "",
          },
          disabled: false,
        };
        if (moment().format("YYYY-MM-DD") == this.date) {
          const inicial = moment(hora.hora, "h:mm A");
          if (inicial.isBefore(actual)) {
            item.text = `${hora.hora} - No disponible`;
            item.disabled = true;
          }
        }
        horas.push(item);
      });
      horas = horas.concat([
        {
          divider: true,
        },
        {
          header: "Agenda tarde",
        },
        {
          divider: true,
        },
      ]);
      this.tarde.forEach((hora) => {
        let item = {
          text: hora.hora,
          value: {
            hora: hora.hora,
            profesional_id: "",
          },
          disabled: false,
        };
        if (moment().format("YYYY-MM-DD") == this.date) {
          const inicial = moment(hora.hora, "h:mm A");
          if (inicial.isBefore(actual)) {
            item.text = `${hora.hora} - No disponible`;
            item.disabled = true;
          }
        }
        horas.push(item);
      });
      return horas;
    },
    horasDisponibles() {
      const agendado = this.horas.agenda.filter((agenda) => {
        return agenda.profesional_id === this.profesional.id;
      });

      let horas = this.listaHoras.map((hora) => {
        if (hora.text != undefined) {
          agendado.forEach((agenda) => {
            const inicio = moment(agenda.inicio, "HH:mm:ss").format("HH:mm");
            const fin = moment(agenda.fin, "HH:mm:ss").format("HH:mm");
            this.iteracion(inicio, fin).forEach((hr) => {
              if (
                moment(hr, "HH:mm:ss").format("h:mm A") == hora.value.hora &&
                agenda.fecha == this.date
              ) {
                hora.text = `${hora.value.hora} - Agendado`;
                hora.disabled = true;
                hora.value.profesional_id = agenda.profesional_id;
              }
            });
          });
          if (
            hora.value.profesional_id != this.profesional.id &&
            hora.value.profesional_id != ""
          ) {
            hora.text = hora.value.hora;
            hora.disabled = false;
            hora.value.profesional_id = "";
          }
        }
        return hora;
      });
      return horas;
    },
  },
  watch: {
    id(val) {
      if (val != undefined || val != null) this.$refs.clientes.reset();
      return;
    },
    date(val) {
      this.loadHoras();
      return this.date;
    },
    servicio(val) {
      this.loadProfesionales();
      return this.servicio;
    },
  },
};
</script>
